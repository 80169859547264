import React from 'react';
import 'regenerator-runtime/runtime';
import Dashboard from '../components/dashboard/dashboard'
import RDGPageLayout from '../components/decorators/RDGPageLayout';
import { createProductVersion } from '@/lib/api/productAPI';
import { getAllDraftOrPublishedProductRecords, searchAllDraftOrPublishedProductRecords } from '@/lib/api/searchProductsAPI';
import {getTermsForVocab} from '@/lib/api/schemaVocabApi';
import {putArchiveDocumentsFileKindMapInCache}  from '@/lib/api/archiveFilesCachedFetch';
import {get} from '@/lib/api/server-side/api-service';
import getConfig from 'next/config';

const {publicRuntimeConfig} = getConfig();

export default function Home(props) {
  putArchiveDocumentsFileKindMapInCache(props.archiveDocumentsFileKindMap);
  return (
    <RDGPageLayout >
      <Dashboard createProductVersion={createProductVersion} getAllDraftOrPublishedProductRecords={getAllDraftOrPublishedProductRecords} 
      searchAllDraftOrPublishedProductRecords={searchAllDraftOrPublishedProductRecords} getTermsForVocab={getTermsForVocab} 
      disseminationSiteUrl={publicRuntimeConfig.disseminationSiteUrl}/>
    </RDGPageLayout>
  )
}

const getAllArchiveDocumentsByFileKindMap = async (ctx) => {
    const url = `/admin/archive/documents`;
    const response = await get('maintainer', url, null, null, ctx);
    const archiveDocumentsArray = response.data;
    let archiveDocumentsFileKindMap = {}

    archiveDocumentsArray && Array.isArray(archiveDocumentsArray) && archiveDocumentsArray.forEach((doc) => {
        let fileKind = doc.properties.filter(property => property.field === "fileKind");
        fileKind = fileKind[0] && fileKind[0].value || "";
        archiveDocumentsFileKindMap[fileKind] = doc.fileName
    })
    return archiveDocumentsFileKindMap;
}

export const getServerSideProps = (async (ctx) => {
    const archiveDocumentsFileKindMap = await getAllArchiveDocumentsByFileKindMap(ctx);
    return {
        props: {
        "archiveDocumentsFileKindMap" : archiveDocumentsFileKindMap
        }
    };
});
