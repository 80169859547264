import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import Link from 'next/link';
import styles from '../../styles/layout.module.css'
import ReactPaginate from 'react-paginate';
import moment from 'moment-timezone';
import { SearchRowAction } from './types';
import { roles } from '@/lib/api/server-side/radius-api/constants/roles';
import {Badge} from 'react-bootstrap';
import { useUserContext } from '@/lib/context/userContext';
import { hasAgencyRoleByCode, hasARole } from '@/lib/api/server-side/radius-api/has-access';
import RdgAlert from '../common/RdgAlert/RdgAlert';
import SpinLoadingIndicatorModal from '../common/SpinLoadingIndicatorModal/SpinLoadingIndicatorModal';
import ActionsDropdownButton from '../ActionButton/ActionsDropdownButton';
import RDGImage from "@/components/common/image/RDGImage";

const SearchResultsTable = (props: { searchResults: { numFound: number; response: any; }, 
    onRowAction: (action: SearchRowAction, product: any) => any , 
    agencyCodeToNameMap: any,
    disseminationSiteUrl: string}) => {

    const recordsToBeDisplayedPerPage = 20;
    const totalRecords = props.searchResults.response ? props.searchResults.response.length : 0;
    const recordsData = props.searchResults.response;
    const showPagination = totalRecords > recordsToBeDisplayedPerPage;
    const pageCount = Math.ceil(totalRecords / recordsToBeDisplayedPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageProperties, setCurrentPageProperties] = useState({
        "pageNumber": 0,
        "firstElementIndex": 0,
        "lastElementIndex": 20
    });
    const [alertProperties, setAlertProperties] = useState({ showAlert: false, alertType: "warning", alertHeading: "", alertBody: "" });
    const [isProcessing, setIsProcessing] = useState(false);

    const handlePageClick = (event) => {
        const currentPageFirstElementIndex = event.selected * recordsToBeDisplayedPerPage;
        const currentPageLastElementIndex = event.selected * recordsToBeDisplayedPerPage + recordsToBeDisplayedPerPage;
        setCurrentPage(event.selected);
        setCurrentPageProperties({
            pageNumber: event.selected,
            firstElementIndex: currentPageFirstElementIndex,
            lastElementIndex: currentPageLastElementIndex
        });
    };
    
    const user = useUserContext();

    const getCurrentPageRecords = () => {
        return recordsData.slice(currentPageProperties.firstElementIndex, currentPageProperties.lastElementIndex);
    }

    const getWorkflowEligibleTransition = (productRecord) => {
        let workflowTransitions = [] 
        const agencyCode = productRecord.imeta_agencyID;
        if(productRecord.status === 'published') {
            if(hasAgencyRoleByCode(agencyCode, roles.record_update, user.rolesAndGroups)){
                workflowTransitions.push({
                    "name":'edit',
                    "label":'Edit',
                })
            }
        }
        if(productRecord.status === 'draft') {
            if(hasAgencyRoleByCode(agencyCode, roles.record_publish, user.rolesAndGroups)){
                workflowTransitions.push({
                    "name":'publish',
                    "label":'Publish',
                });
            }
            workflowTransitions.push({
                "name":'uploadfiles',
                "label":'Upload files',
            });
            if((agencyCode && hasAgencyRoleByCode(agencyCode, roles.record_publish, user.rolesAndGroups)) || (!agencyCode && hasARole(roles.record_delete, user.rolesAndGroups))){
                workflowTransitions.push({
                    "name":'delete',
                    "label":'Delete',
                });
            }
        }
        // if(productRecord.imeta_files!=undefined){
        //     workflowTransitions.push({
        //         "name":'downloadfiles',
        //         "label":'Download Files',
        //     });
        // }
        
        return workflowTransitions;
    }
    return (
        <div>
            {<SpinLoadingIndicatorModal show={isProcessing} />}
            {alertProperties.showAlert && <RdgAlert {...alertProperties} />}
            <div className={styles.tableContainer}><br/>
            {
                (totalRecords < 1) ? <p>Showing {totalRecords} of {totalRecords} results</p> :
                (pageCount === (currentPage + 1)) ? <p>Showing {currentPageProperties.firstElementIndex + 1}-{totalRecords} of {totalRecords} results</p>:<p>Showing {currentPageProperties.firstElementIndex + 1}-{currentPageProperties.lastElementIndex} of {totalRecords} results</p>
            }           
                <Table striped hover className={styles.recordsTable}>
                    <thead>
                        <tr>
                            {/* <th></th> */}
                            <th>Title</th>
                            <th>Status</th>
                            <th>Entry Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getCurrentPageRecords().map((productRecord, productRecordIndex) =>
                            <tr key={`dashboard_row_${productRecordIndex}`}>
                                {/* <td>{productRecordIndex + 1 + currentPage * recordsToBeDisplayedPerPage}</td> */}
                                <td>
                                    <div style={{display: 'flex', gap: '5px'}}>
                                        <Link
                                            href={`/ingest/${productRecord.product_id}#Review`}
                                            as={`/ingest/${productRecord.product_id}#Review`}
                                            style={{color: "#0d3880", fontWeight: "700", paddingTop: '5px', marginLeft: '4px', textDecoration: 'none'}}
                                            data-cy={`/ingest/${productRecord.product_id}#Review`}>

                                            {productRecord.dcterms_title ? productRecord.dcterms_title : "Untitled Product"}

                                        </Link>

                                        <div>
                                            {productRecord.product_type &&
                                            <>
                                            <Badge pill className={styles['pill-style']}>
                                            {productRecord.product_type.split(" ")[1] === 'dataset' ? 
                                                <span><RDGImage src={`/images/icons/dataset.svg`} alt='Research Data Gov' />  Dataset </span> : 
                                                <span><RDGImage src={`/images/icons/datafile.svg`} alt='Research Data Gov' /> Data file</span>
                                            }
                                            </Badge>{" "}
                                            </>
                                            }
                                        </div>
                                    </div>
                                    <p>
                                        <b>{props.agencyCodeToNameMap[productRecord.imeta_agencyID] ? props.agencyCodeToNameMap[productRecord.imeta_agencyID] : "-"}</b>&nbsp;
                                            {(productRecord.imeta_agencyDatasetID) ? `Agency ID-${productRecord.imeta_agencyDatasetID}` : (productRecord.imeta_agencyDatafileID) ? `Agency ID-${productRecord.imeta_agencyDatafileID}` : ""}
                                    </p>
                                    <p>RDG {productRecord.product_id}</p>
                                </td>
                                <td>{productRecord.status}</td>
                                
                                <td>{moment.utc(productRecord.created).tz("America/New_York").format("MM-DD-YYYY")}</td>

                                <td>
                                    <div className="d-flex flex-column align-items-center">
                                    <ActionsDropdownButton
                                        product={{...productRecord, productId: productRecord.product_id, productVersionId: productRecord.product_version_id, formik:{'imeta_agencyID':productRecord.imeta_agencyID}}}
                                        hasValidationErrors={false} // adding the value as false as in dashboard it is unknown
                                        workflowTransitions={getWorkflowEligibleTransition(productRecord)}
                                        disseminationSiteUrl={props.disseminationSiteUrl}
                                        setIsProcessing={setIsProcessing}
                                        setAlertProperties={setAlertProperties}
                                    />
                                    </div>
                                </td>
                            </tr>)}
                    </tbody>
                </Table>
                {totalRecords < 1 && <p className={styles.textCenter}>No records found</p>}
                <div className={styles.paginationCont}>
                    {showPagination && <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="Previous"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        activeClassName={"page-active"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                    />}

                </div>

            </div>
        </div>
    );
}

export default SearchResultsTable;