import React, { useEffect, useState } from 'react';
import { Row, Col, Button, InputGroup, FormControl, Form, Table, Dropdown, DropdownButton } from 'react-bootstrap';
import Link from 'next/link';
import styles from '../../styles/layout.module.css'
import { useRouter } from 'next/router'
import RDGButton from '../common/button/RDGButton';
import { useSession } from "next-auth/react";
import SpinLoadingIndicator from '@/components/common/SpinLoadingIndicator';
import { RDGProductTypes } from '@/lib/config/constant';
import SearchResultsTable from './SearchResultsTable';
import { SearchRowAction } from './types';
import { StepNavEnum } from '../sidebar/types';
import SpinLoadingIndicatorModal from '@/components/common/SpinLoadingIndicatorModal/SpinLoadingIndicatorModal';
import { roles } from '@/lib/api/server-side/radius-api/constants/roles';
import {downloadBatchIngestTemplate} from '@/lib/api/archiveFilesCachedFetch';
import RdgAlert from '../common/RdgAlert/RdgAlert';
import { useUserContext } from "../../lib/context/userContext";

const Dashboard = (props: { 
    createProductVersion: (productType: string, userEmail: string) => Promise<any>, 
    getAllDraftOrPublishedProductRecords: (searchUserRecordsOnly: boolean, rolesAndGroups: any) => Promise<any>,
    searchAllDraftOrPublishedProductRecords: (searchUserRecordsOnly: boolean, searchInputValue: string, rolesAndGroups: any) => Promise<any>
    getTermsForVocab: (vocabName: string) => Promise<any>,
    disseminationSiteUrl: string
}) => {
    const [recordsData, setRecords] = useState({ "numFound": 0, "response": [] })
    const [searchInputValue, setSearchInputValue] = useState('');
    const [hasError, setHasError] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchUserRecordsOnly, setSearchUserRecordsOnly] = useState(true);
    const { data: session } =  useSession();
    const user = useUserContext();
    const router = useRouter();
    const [agencyCodeToNameMap, setAgencyCodeToNameMap]= useState({});
    const [showFileUnavailableAlert, setShowFileUnavailableAlert] = useState(false);

    // this below useEffect will be executed by default when component loads and then anytime the value of searchUserRecordsOnly changes 
    useEffect(() => {
        getDraftAndPublishedProductRecords();
    }, [searchUserRecordsOnly]);

    const onProductTypeSelection = async (productType) => {
        setIsLoading(true);
        const newProduct = await props.createProductVersion(productType, user.email);
        if (newProduct !== 'error') {
            router.push(`/ingest/${newProduct.productId}`);
        } else {
            router.push('/error/technical');
        }
    }

    const getDraftAndPublishedProductRecords = async () => {
        setIsProcessing(true);
        try {
            const records = await props.getAllDraftOrPublishedProductRecords(searchUserRecordsOnly, user.rolesAndGroups);
            const agencyVocabTerm = await props.getTermsForVocab('rdg_agency_list');
            let tempAgencyCodetoNameMap = {};
            agencyVocabTerm.forEach(term=>{
                tempAgencyCodetoNameMap[term.code] = term.label;
            })
            setAgencyCodeToNameMap(tempAgencyCodetoNameMap);
            setHasError(false);
            setRecords(records);
        } catch (err) {
            setHasError(true);
        }
        setIsProcessing(false);
    }

    const doKeyWordSearch = async () => {
        setIsProcessing(true);
        try {
            const searchRecord = await props.searchAllDraftOrPublishedProductRecords(searchUserRecordsOnly, searchInputValue, user.rolesAndGroups);
            setRecords(searchRecord);
        } catch (err) {
            setHasError(true);
        }
        setIsProcessing(false);
    }

    const handleViewAllButtonClick = () => {
        setSearchInputValue('');
        getDraftAndPublishedProductRecords();
    }
    const handleEnter = (event) => {
        if (event && event.charCode === 13) {
            doKeyWordSearch();
        }
    }
    const handleCheckboxSelectionChange = (event) => {
        setSearchUserRecordsOnly(event.target.value === 'true');
    };

    const onRowAction = (action: SearchRowAction, productRow: any) => {

        if (action === 'UPLOAD_FILE') {
            router.push({
                pathname: `/ingest/[productId]`,
                hash: StepNavEnum.FILE_UPLOAD,
                query: {
                    productId: productRow.product_id
                }
            })
        }

        else if (action === 'PREVIEW') {
            window.open( props.disseminationSiteUrl+ '/product/' + productRow.product_id, '_blank' );
        }

        else if (action === 'UNPUBLISH') {
            router.push({
                pathname: '/ingest/[productId]',
                hash: StepNavEnum.UNPUBLISH,
                query: {
                    productId: productRow.product_id
                }
            })
        }
    }

    const selectedProductTypeSeletionList = Object.keys(RDGProductTypes).map(element => {
        return (
            // @ts-ignore
            <Dropdown.Item key={RDGProductTypes[element].value} data-cy={RDGProductTypes[element].displayName} name={RDGProductTypes[element].displayName} onClick={() => {
                onProductTypeSelection(RDGProductTypes[element].value);
            }}>
                {RDGProductTypes[element].displayName}
            </Dropdown.Item>
        )
    });

    const handleDonwoloadBatchIngestTemplateClick = async() => {
        const isSuccess = await downloadBatchIngestTemplate()
        if(!isSuccess) {
            setShowFileUnavailableAlert(true);
        }
    }
    return (
        <div>
            {<SpinLoadingIndicatorModal show={isLoading} />}
            {showFileUnavailableAlert && 
                <div className="d-flex flex-column m-auto align-items-center">
                    <RdgAlert alertType="warning" alertHeading="" alertBody={"File Unavailable, Please try again after sometime"} />
                </div>
            }
            <div className={styles.dashboardBody}>
                <Row>
                    <Col className={styles.col}>
                        <p>This dashboard allows you to view and edit previously uploaded metadata and to add new records.</p>
                        { user.roles[roles.record_create] && 
                            <DropdownButton title="Add Record" className={styles.addRecord} data-cy={'addRecordDropdown'} id="addRecordDropdown">
                                {selectedProductTypeSeletionList}
                            </DropdownButton>
                        }
                        { user.roles[roles.batch_create] &&
                        <Link href="/ingest/batch" as="/ingest/batch" legacyBehavior>
                            <Button data-cy={'batchUploadButton'} className={styles.themeButton} id="batchUploadButton">Batch Upload</Button>
                        </Link>
                        }
                    </Col>
                    <Col className={styles.col}>
                        <p>Uploading multiple records (“batch upload”) requires use of the specified metadata template spreadsheet. You can download that template here.</p>
                        <Button data-cy={'downloadTemplate'} className={styles.secondaryButton} onClick={handleDonwoloadBatchIngestTemplateClick}>Download template</Button>
                    </Col>
                </Row>
                {user.roles[roles.record_view] && 
                <>
                    <section className={["grid-row"] + " " + styles["search-row"] + " " + styles["text-center"]}>
                    <div className={styles['grid-centered']}>
                       <div className={styles.tableContainer + " " + styles["search-heading"]}>
                            <label htmlFor='searchInput' className={styles['show-for-sr']}>
                                 Search Box
                            </label>
                                <input
                                className={styles.indexPageSearchInput}
                                type="text"
                                id="searchtext"
                                aria-label="business"
                                aria-describedby="basic-addon2"
                                value={searchInputValue}
                                onChange={(e) => setSearchInputValue(e.target.value)}
                                onKeyPress={(e) => handleEnter(e)}
                                 />
                            <RDGButton name={'go'} dataCy={'searchGoButton'} isPrimary lg onClick={doKeyWordSearch}>Go</RDGButton>
                            <RDGButton name={'viewAll'} dataCy={'searchViewAllButton'} isPrimary lg onClick={handleViewAllButtonClick}>View All</RDGButton>
                            <br/>
                            <div >
                            <Row>
                                <Col md={2}>
                                <strong className={styles["radio-align"]}>Filter by:</strong>
                                </Col>
                                <Col md={8}>  
                                <div className={["d-inline-block"] + " " + styles["radio-align"]} onChange={handleCheckboxSelectionChange}>
                                    <Form.Check className={styles["radio-style"]} type={'radio'} id={`default-radio`} name={`records`} label={`Agency records`}
                                        value={"false"} />
                                    <Form.Check className={styles["radio-style"]} type={'radio'} id={`default-radio`} name={`records`} label={`Only my records`}
                                        defaultChecked value={"true"} />
                                </div> 
                                </Col>  
                            </Row>
                            </div>   
                        </div>
                    </div>
                    </section>
                    {isProcessing && <SpinLoadingIndicator className="d-flex align-items-center m-auto" animation='border' />}
                    {!isProcessing && hasError && "Unable to load results.."}
                    {!isProcessing && !hasError && <SearchResultsTable searchResults={recordsData} onRowAction={onRowAction} agencyCodeToNameMap={agencyCodeToNameMap} disseminationSiteUrl={props.disseminationSiteUrl}/>}
                </>
                }
            </div>
        </div>
    );
}

export default Dashboard;
