import http from './httpCore';

export const getAllDraftOrPublishedProductRecords = async (currentUserOnly:Boolean, rolesAndGroups: any): Promise<any> => {
    let url = `/product/search/draftOrPublishedRecords${currentUserOnly ? "" : "/agency"}`;
    if(!currentUserOnly){
        return http.post(url, {
            "query":"",
            "fields": "*,fileContent:[value v=\"\"]",
            "sort": "created desc",
            "offset": 0,
            "limit": 5000
        },
        {
          headers: {
            "rolesandgroups": JSON.stringify(rolesAndGroups)
          }
        }
    );
    }
    return http.post(url, {
            "query":"",
            "fields": "*,fileContent:[value v=\"\"]",
            "sort": "created desc",
            "offset": 0,
            "limit": 5000
        }
    );
}

export const searchAllDraftOrPublishedProductRecords = async (currentUserOnly, search, rolesAndGroups: any): Promise<any> => {
    let url = `/product/search/draftOrPublishedRecords${currentUserOnly ? "" : "/agency"}`;
    if(!currentUserOnly){
        return http.post(url, {
            "query":"",
            "fields": "*,fileContent:[value v=\"\"]",
            "sort": "created desc",
            "offset": 0,
            "limit": 5000
        },
        {
          headers: {
            "rolesandgroups": JSON.stringify(rolesAndGroups)
          }
        }
    );
    }
    return http.post(url, {
            "query": `"${search}"`,
            "fields": "*,score,fileContent:[value v=\"\"]",
            "sort": "score desc",
            "offset": 0,
            "limit": 5000
        });
}

